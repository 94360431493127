// @ts-nocheck

import React, { useEffect } from 'react';
import Text from '@Src/components/Text';
import { useApp } from '@Src/context/AppContext';

interface AdvanceInquirySubmittedViewProps {
  language?: string
  advanceInquiryId?: string
  theme?: string
}

const AdvanceInquirySubmittedView = ({
  language = 'en',
  theme = 'night',
  advanceInquiryId
}: AdvanceInquirySubmittedViewProps) => {

  useEffect(() => {
    if (typeof window !== 'undefined' && window.dataLayer instanceof Array) {
      window.dataLayer.push({
        type: 'event',
        event: 'thank_you_page',
        ecommerce: {
          transaction_id: advanceInquiryId
        }
      })
    }
  }, [])
  return (
    <div style={{ textAlign: 'center', padding: 20 }}>
      <Text theme={theme} heading01={`Congratulations!`} />
      <Text theme={theme} htmlText={`You have now received an email with the offer in detail.`} />
    </div>
  )
}

export default AdvanceInquirySubmittedView