import React, { ReactElement, useEffect, useState } from 'react';
import { IPageData, IPageProps } from '@Types/ContentfulTypes';
import { usePageTheme, useApp, useSystemMode } from '@Context/AppContext';
import '@GraphQL/fragments';
import AdvanceInquirySubmittedView from '@Src/components/Advance/Inquiry/Submitted';
import AdvanceInquiryLayout from '@Src/components/Advance/Inquiry/Layout';
import ContentLayout from '@Src/components/sitestructure/ContentLayout';

export interface Props extends IPageProps {
  data: {
    contentfulPageTypePitch: IPageData;
  };
  locale?: string
  path?: string
  advanceInquiryId: string
}

const AdvanceInquirySubmittedPage = ({ data, location, pageContext, transitionStatus }: Props): ReactElement => {
  //const { title, seoSettings, content } = data.contentfulPageTypePitch;
  const title = '', seoSettings = 'label pitch'
  let locale = 'en-US'
  const { setTheme: setPageTheme } = usePageTheme();
  const { mode, pageType, setPageType, setPageTitle, setBackUrl } = useApp();
  const [localTransStatus, setLocalTransStatus] = useState<string>(transitionStatus);
  const [content, setContent] = useState(null)
  const language = locale.split(/-/)[0]
  const [advanceInquiryId, setAdvanceInquiryId] = useState<string | undefined | null>(null)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const query = new URLSearchParams(window.location.search)
      setAdvanceInquiryId(query.get('advanceInquiryId')!)
    }
  })
  useEffect(() => {
    setBackUrl(`/advance/inquiry/statements?advanceInquiryId=${advanceInquiryId}`)
  }, [advanceInquiryId])
  useEffect(() => {
    if (transitionStatus == 'exiting') {
      setBackUrl(null)
    }
    if (pageType === 'index' && transitionStatus == 'exiting') {
      setLocalTransStatus(transitionStatus + 'Index');
    } else {
      setLocalTransStatus(transitionStatus);
    }

    if (transitionStatus == 'entering' || transitionStatus == 'entered') {
      setPageTheme(mode == 'night' ? 'dark' : 'light');
      setPageTitle(title);
      setPageType('advance');
    }
  }, [transitionStatus]);
  useEffect(() => {
    if (transitionStatus == 'entered') {
      setPageTheme(mode == 'night' ? 'dark' : 'light');
    }
  }, [mode]);

  return (
    <ContentLayout type={pageType} fullWidth transitionState={localTransStatus}>
      <div style={{ padding: 0 }}>
        {/* @ts-ignore */}
        <AdvanceInquiryLayout
          pageType={pageType}
          transitionState={localTransStatus}
          currentStepId={"submited"}
          locale={locale}
          theme={mode}
          /* @ts-ignore */
          advanceInquiryId={advanceInquiryId}
        >
          <AdvanceInquirySubmittedView
            /* @ts-ignore */
            advanceInquiryId={advanceInquiryId}
            locale={locale}
            theme={mode}
          />
        </AdvanceInquiryLayout>
      </div>
    </ContentLayout>
  );
};

export default AdvanceInquirySubmittedPage;